import React, { Component } from 'react';
import Slider from "react-slick";

export class CarouselTFO extends Component {

    
    render() {
        let settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            autoplay: true,
            slidesToScroll: 1
        };

        return (
            
            <Slider {...settings}>
                <div>
                    <img src={require('../../../src/assets/img/tfo/the-epic-oceanfront.jpg')} alt="Epic Oceanfront" className="w-100"/>
                </div>

                <div>
                    <img src={require('../../../src/assets/img/tfo/2_epic_collection_bedroom.jpg')} alt="Epic Oceanfront" className="w-100"/>
                </div>

                <div>
                    <img src={require('../../../src/assets/img/tfo/3_oceanfront.jpg')} alt="Epic Oceanfront" className="w-100"/>
                </div>

                <div>
                    <img src={require('../../../src/assets/img/tfo/4_kiin_rooftop.jpg')} alt="Epic Oceanfront" className="w-100"/>
                </div>

                <div>
                    <img src={require('../../../src/assets/img/tfo/5_oceanfront_rooftop.jpg')} alt="Epic Oceanfront" className="w-100"/>
                </div>

                <div>
                    <img src={require('../../../src/assets/img/tfo/6_epic_oceanfront.jpg')} alt="Epic Oceanfront" className="w-100"/>
                </div>
            </Slider>

        );
    }
};

