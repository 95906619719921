import React, { Component } from 'react';
import Slider from "react-slick";

export class CarouselTFD extends Component {

    
    render() {
        let settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            autoplay: true,
            slidesToScroll: 1
        };

        return (
            
            <Slider {...settings}>
                <div>
                    <img src={require('../../../src/assets/img/tfd/the-epic-downtown.jpg')} alt="Epic Downtown" className="w-100"/>
                </div>

                <div>
                    <img src={require('../../../src/assets/img/tfd/2_the_fives_downtown.jpg')} alt="Epic Downtown" className="w-100"/>
                </div>

                <div>
                    <img src={require('../../../src/assets/img/tfd/3_fives_rooftop_restaurant.jpg')} alt="Epic Downtown" className="w-100"/>
                </div>

                <div>
                    <img src={require('../../../src/assets/img/tfd/4_the_fives_downtown.jpg')} alt="Epic Downtown" className="w-100"/>
                </div>

                <div>
                    <img src={require('../../../src/assets/img/tfd/5_rooftop.jpg')} alt="Epic Downtown" className="w-100"/>
                </div>

                <div>
                    <img src={require('../../../src/assets/img/tfd/6_the_fives_downtown_lobby.jpg')} alt="Epic Downtown" className="w-100"/>
                </div>
            </Slider>

        );
    }
};

