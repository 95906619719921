import React, { Component } from 'react';
import Slider from "react-slick";

export class CarouselTFR extends Component {

    
    render() {
        let settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            autoplay: true,
            slidesToScroll: 1
        };

        return (
            
            <Slider {...settings}>
                <div>
                    <img src={require('../../../src/assets/img/tfr/the-epic-residence.jpg')} alt="Epic Residences" className="w-100"/>
                </div>

                <div>
                    <img src={require('../../../src/assets/img/tfr/2_la_brasserie.jpg')} alt="Epic Residences" className="w-100"/>
                </div>

                <div>
                    <img src={require('../../../src/assets/img/tfr/3_deluxe_room.jpg')} alt="Epic Residences" className="w-100"/>
                </div>

                <div>
                    <img src={require('../../../src/assets/img/tfr/4_swim_up_suitee.jpg')} alt="Epic Residences" className="w-100"/>
                </div>

                <div>
                    <img src={require('../../../src/assets/img/tfr/5_flavours.jpg')} alt="Epic Residences" className="w-100"/>
                </div>

                <div>
                    <img src={require('../../../src/assets/img/tfr/6_epic_residences.jpg')} alt="Epic Residences" className="w-100"/>
                </div>
            </Slider>

        );
    }
};

