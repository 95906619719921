import React from 'react'
import { useEffect } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";

export const About = () => {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (

        <section className="content-section mb-2" id="about" data-aos="fade-up" data-aos-duration="1500">
            <div className="container p-5">
                <div className="row">
                    <div className="col-lg-12 mt-5">
                        <h1 className="title text-center">Elevate your expectations</h1>
                        <p className="subtitle">Welcome to Epic Vacation Club, where you can truly enjoy what a travel club experience should be. </p>
                        <p className="subtitle">We thrive on delivering outstanding service and quality, unparalleled experiences, and amusing adventures. </p>
                        <p className="subtitle">Our three distinctive properties are meant to fulfill your every need. Each of our stylishly elegant hotels features incomparable amenities and cutting-edge facilities to create extraordinary experiences at a preferential rate for our guests.</p>
                        <p className="subtitle">Get to know us and let us show you the true meaning of epic when it comes to creating memories while traveling.</p>
                    </div>
                </div>
                
            </div>
        </section>
    )
}

export default About