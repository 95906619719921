// import { useEffect } from 'react';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MetaHead from './components/MetaHead';
//Esp
import NavbarEs from './components/pages/es/Navbar';
import BookingengineEs from './components/pages/es/Bookingengine';
import AboutEs from './components/pages/es/About';
import HotelsEs from './components/pages/es/Hotels';
import ContactEs from './components/pages/es/Contact';
import FooterEs from './components/pages/es/Footer';

//Eng
import NavbarEn from './components/pages/en/Navbar';
import BookingengineEn from './components/pages/en/Bookingengine';
import AboutEn from './components/pages/en/About';
import HotelsEn from './components/pages/en/Hotels';
import ContactEn from './components/pages/en/Contact';
import FooterEn from './components/pages/en/Footer';
// import ImportScriptChat from './components/customHooks/ImportScript'

// import './assets/css/style.css'

const metaEs = (
  <MetaHead
    title="The Epic Vacation Club"
    description="Bienvenido a Epic Vacation Club, donde podrá disfrutar de lo que realmente debe ser la experiencia de un Club de Viajes."
    image="./assets/img/epic_vacations.jpg"
    url="theepicvacationclub.com"
  />
);



const metaEn = (
  <MetaHead
    title="The Epic Vacation Club"
    description="Welcome to Epic Vacation Club, where you can truly enjoy what a travel club experience should be. ."
    image="./assets/img/epic_vacations.jpg"
    url="theepicvacationclub.com"
  />
);


const HomeEs = () => (
  <>
    {metaEs}
    <NavbarEs/>
    <BookingengineEs/>
    <AboutEs/>
    <HotelsEs/>
    <ContactEs/>
    <FooterEs/>
    {/* <ImportScriptChat/> */}
  </>
);


const HomeEn = () => (
  <>
    {metaEn}
    <NavbarEn/>
    <BookingengineEn/>
    <AboutEn/>
    <HotelsEn/>
    <ContactEn/>
    <FooterEn/>
    {/* <ImportScriptChat/> */}
  </>
);


const App = () => {

  return (
    <Router>
      <Routes>
        <Route path="/" caseSensitive={false} element={<HomeEs />} />
        <Route path="/en" caseSensitive={false} element={<HomeEn />} />
      </Routes>
    </Router>

  );

}


export  { App };


