import React from 'react'
import logo from '../../../assets/img/the-epic-logo-white.png'
import imgfooter from '../../../assets/img/img-footer.jpg'


export const Footer = () => {

    return (

        <section className="content-section" id="footer">
            <div className="container p-5 pb-0">

                <div className="row pb-5">

                    <div className="col-lg-6  pb-4 text-center my-border-right">
                        <img src={logo} alt="/en" className="w-35"/>
                    </div>

                    <div className="col-lg-6 text-center pt-3">
                        <h3 className="text-light">Contact</h3>
                        <ul className="pl-0 list-unstyled text-center">
                            <li><a className="fs-6 text-light text-decoration-none d-inline-block" href="tel:18008404070">USA/CAN 1 800 840-4070</a></li>
							<li><a className="fs-6 text-light text-decoration-none d-inline-block" href="tel:+529842420313">REST OF THE WORLD +52 (984) 242-0313</a></li>
                            <li><a className="fs-6 text-light text-decoration-none d-inline-block" href="mailto:info@theepicvacationclub.com">info@theepicvacationclub.com</a></li>
						</ul>
                    </div>
                </div>

            </div>

            <img src={imgfooter} alt="" className="w-100"/>

        </section>
    )
}

export default Footer