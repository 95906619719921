import React from 'react'
import HubspotForm from 'react-hubspot-form'

export const Contact = () => {

    return (

        <section className="content-section background-epic" id="contact" data-aos="fade-up" data-aos-duration="1500">
            <div className="container p-5 pb-0">
                <div className="row pb-5">
                    <div className="col-lg-8 m-auto text-center pt-3">
                        <h3 className="text-light">¡Contáctanos!</h3>

                        <HubspotForm
                            portalId='5943261'
                            formId='6819d2b4-f865-4fc1-837f-6be2b7091db7'
                            loading={<div>Cargando...</div>}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact