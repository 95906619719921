import React from 'react'
import { useEffect } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";

export const About = () => {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (

        <section className="content-section mb-2" id="about" data-aos="fade-up" data-aos-duration="1500">
            <div className="container p-5">
                <div className="row">
                    <div className="col-lg-12 mt-5">
                        <h1 className="title text-center">Eleva tus expectativas</h1>
                        <p className="subtitle">Bienvenido a Epic Vacation Club, donde podrá disfrutar de lo que realmente debe ser la experiencia de un Club de Viajes. </p>
                        <p className="subtitle">Nos esforzamos por ofrecer un servicio y una calidad extraordinarios, experiencias inigualables y aventuras increíbles. </p>
                        <p className="subtitle">Nuestras tres propiedades distintivas están pensadas para satisfacer todas sus necesidades. Cada uno de nuestros elegantes hoteles cuenta con servicios incomparables e instalaciones de vanguardia para crear experiencias extraordinarias a un precio preferencial para nuestros huéspedes.</p>
                        <p className="subtitle">Conózcanos y permítanos mostrarle el verdadero significado de lo épico cuando se trata de crear memorias de viaje.</p>
                    </div>
                </div>
                
            </div>
        </section>
    )
}

export default About