import React from 'react'
import { CarouselTFB } from '../../carousel/CarouselTFB'
import { CarouselTFD } from '../../carousel/CarouselTFD'
import { CarouselTFO } from '../../carousel/CarouselTFO'
import { CarouselTFR } from '../../carousel/CarouselTFR'
import tfduo from '../../../assets/img/the-epic-duo.jpg'

import { useEffect } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";

export const Hotels = () => {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);

    return (

        <section className="content-section" id="hotels">
            <div className="container px-5">
                <div className="row pb-lg-5">
                    <div className="col-lg-6 px-4" data-aos="fade-right" data-aos-duration="1500">
                        <CarouselTFB/>
                    </div>

                    <div className="col-lg-6 px-4" data-aos="fade-up" data-aos-duration="1500">
                        <h1 className="title text-center">Epic Vacation Club Beach</h1>
                        <p className="subtitle">Immerse yourself in the serenity of our AAA Four-Diamond hotel, ideal for a unique and exclusive vacation. Relax on our white sand beach, enjoy turquoise waters, and discover our restaurants that offer extraordinary dishes and unique flavors. Visit the perfect place for a family vacation or a romantic getaway in the heart of the Mexican Caribbean.</p>
                        <div className="row  padding-price">
                            <div className="col-lg-8">
                                <h2 className="price">From $39 USD*</h2>
                                <small className="subtitle taxes">*Rate for a minimum of four nights with check-in on Sunday in an all-inclusive basis, excludes environmental tax.</small>
                                <small className="subtitle taxes">*Rate per person in double occupancy.</small>
                                <small className="subtitle taxes">*Free for children up to 12 years old. </small>
                                <small className="subtitle taxes">*One-way transportation included.</small>
                                
                            </div>
                            
                            <div className="col-lg-4 text-md-center">
                                <a className="btn btn-book btn-lg" target="_blank" rel="noreferrer" href="https://www.reservhotel.com/playa-del-carmen-mexico/epic-beach/booking-engine/ibe5.main?hotel=10868&showcalendar=Y">Book Now</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row pt-5 pb-lg-5">
                    <div className="col-lg-6 px-4" data-aos="fade-right" data-aos-duration="1500">
                        <CarouselTFD/>
                    </div>

                    <div className="col-lg-6 px-4" data-aos="fade-up" data-aos-duration="1500">
                        <h1 className="title text-center">Epic Vacation Club Downtown</h1>
                        <p className="subtitle">Start the day with new experiences in our charming hotel, give a touch of glamour to your stay and experience the magic in downtown Playa del Carmen. Delight your palate with our international gourmet cuisine, tequila shots at our signature bar, and the best coffee corner in town. Let your eyes be impressed by the best view of the Mexican Caribbean.</p>

                        <div className="row padding-price">
                            <div className="col-lg-8">
                                <h2 className="price">From $39 USD*</h2>
                                <small className="subtitle taxes">*Breakfast included.</small>
                                <small className="subtitle taxes">*Rate per room in double occupancy.</small>
                                <small className="subtitle taxes">*Taxes not included.</small>                                
                                <small className="subtitle taxes">*One-way transportation included.</small>

                            </div>
                            
                            <div className="col-lg-4 text-md-center">
                                <a className="btn btn-book btn-lg" target="_blank" rel="noreferrer" href="https://www.reservhotel.com/playa-del-carmen-mexico/epic-downtown/booking-engine/ibe5.main?hotel=10867&showcalendar=Y">Book Now</a>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <div className="row pt-5 pb-lg-5">
                    <div className="col-lg-6 px-4" data-aos="fade-right" data-aos-duration="1500">
                        <CarouselTFO/>
                    </div>

                    <div className="col-lg-6 px-4" data-aos="fade-up" data-aos-duration="1500">
                        <h1 className="title text-center">Epic Vacation Club Oceanfront</h1>
                        <p className="subtitle">Discover the Riviera Maya's best-kept secret: Puerto Morelos. Lay back and relax or embark on a new adventure at our beautiful boutique hotel on the shores of Mexico. Soak up the sun by the outdoor infinity pool, enjoy a cocktail at the bar, and the best dishes from our restaurants. Find the revitalizing atmosphere you need. </p>

                        <div className="row  padding-price">
                            <div className="col-lg-8">
                                <h2 className="price">From $39 USD*</h2>
                                <small className="subtitle taxes">*Breakfast included.</small>
                                <small className="subtitle taxes">*Rate per room in double occupancy.</small>
                                <small className="subtitle taxes">*Taxes not included.</small>                                
                                <small className="subtitle taxes">*One-way transportation included.</small>

                            </div>
                            
                            <div className="col-lg-4 text-md-center">
                                <a className="btn btn-book btn-lg" target="_blank" rel="noreferrer" href="https://www.reservhotel.com/cancun-mexico/epic-oceanfront/booking-engine/ibe5.main?hotel=10866&showcalendar=Y">Book Now</a>
                            </div>
                        </div>

                    </div>
                </div>


                <div className="row pt-5 pb-lg-5">
                    <div className="col-lg-6 px-4" data-aos="fade-right" data-aos-duration="1500">
                        <CarouselTFR/>
                    </div>

                    <div className="col-lg-6 px-4" data-aos="fade-up" data-aos-duration="1500">
                        <h1 className="title text-center">Epic Residences</h1>
                        <p className="subtitle">Enjoy an exceptional level of relaxation at our AAA, Four-Diamond hotel. Feel the comfort of our world-class facilities with spacious rooms, seven fabulous pools, and, above all, relax your mind and body on the Mexican Caribbean's white-sand beaches and stunning turquoise waters.</p>

                        <div className="row  padding-price">
                            <div className="col-lg-8">
                                <h2 className="price">From $39 USD*</h2>
                                <small className="subtitle taxes">*Breakfast included.</small>
                                <small className="subtitle taxes">*Rate per room in double occupancy.</small>
                                <small className="subtitle taxes">*Taxes not included.</small>                                
                                <small className="subtitle taxes">*One-way transportation included.</small>
                            </div>
                            
                            <div className="col-lg-4 text-md-center">
                                <a className="btn btn-book btn-lg" target="_blank" rel="noreferrer" href="https://www.reservhotel.com/playa-del-carmen-mexico/epic-residences/booking-engine/ibe5.main?hotel=10869&showcalendar=Y">Book Now</a>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="row pt-5 pb-lg-5">
                    <div className="col-lg-6" data-aos="fade-right" data-aos-duration="1500">
                        <img src={tfduo} alt="Epic Residences" className="w-100"/>
                    </div>

                    <div className="col-lg-6 px-4" data-aos="fade-up" data-aos-duration="1500">
                        <h1 className="title text-center">Epic Duo</h1>
                        <p className="subtitle">Are you undecided between a vacation full of music, energy, and fun or a getaway for resting and relaxation? Let us seduce your emotions with our Epic Duo package. We give you the alternative to experience the best of two worlds: the excitement of Playa del Carmen and the charm and tranquility of Puerto Morelos. In which order? You decide! Let us help you design the vacation of your dreams.</p>

                        <div className="row  padding-price">
                            <div className="col-lg-8">
                                <h2 className="price">From $39 USD*</h2>
                                <small className="subtitle taxes">*Breakfast included.</small>
                                <small className="subtitle taxes">*Rate per room in double occupancy.</small>
                                <small className="subtitle taxes">*Taxes not included.</small>                                
                                <small className="subtitle taxes">*One-way transportation included.</small>
                            </div>
                            
                            <div className="col-lg-4 text-md-center">
                                <a className="btn btn-book btn-lg" target="_blank" rel="noreferrer" href="https://landing.theepicvacationclub.com/sales/order?token=8c01b5663e38a278ecf2d63403dc02e596f4c54d&category=39&hotel=10">Book Now</a>
                            </div>
                        </div>

                    </div>
                </div>


                
            </div>
        </section>
    )

}



  
export default Hotels