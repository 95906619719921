import React, { Component } from 'react';
import Slider from "react-slick";

export class CarouselTFB extends Component {

    
    render() {
        let settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            autoplay: true,
            slidesToScroll: 1
        };

        return (
            
            <Slider {...settings}>
                <div>
                    <img src={require('../../../src/assets/img/tfb/the-epic-beach.jpg')} alt="Epic Beach" className="w-100"/>
                </div>

                <div>
                    <img src={require('../../../src/assets/img/tfb/2_the_fives_aerial_club_natura.jpg')} alt="Epic Beach" className="w-100"/>
                </div>

                <div>
                    <img src={require('../../../src/assets/img/tfb/3_one_bedroom_penthose.jpg')} alt="Epic Beach" className="w-100" />
                </div>

                <div>
                    <img src={require('../../../src/assets/img/tfb/4_one_bedroom_penthose.jpg')} alt="Epic Beach" className="w-100"/>
                </div>

                <div>
                    <img src={require('../../../src/assets/img/tfb/5_the_fives_beach_sea_olive.jpg')} alt="Epic Beach" className="w-100" />
                </div>

                <div>
                    <img src={require('../../../src/assets/img/tfb/6_the_fives_beach_one_bedroom.jpg')} alt="Epic Beach"  className="w-100" />
                </div>
            </Slider>

        );
    }
};

