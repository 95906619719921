import React from 'react'


export const Bookingengine = () => {


    return (

        <div className="container-fluid w-100 d-lg-block d-xl-block" id="one">

            <div className="row">
                <div className="col-lg-12 background-epic" id="floatingBookingEngine">
                    <div className="card rounded-0 border-0 background-epic">
                        <div className="card-body">
                            <form  target="_blank" id="formbookingengine">

                                <div className="row text-center pb-1 pt-1 margin-booking">
                                    
                                    {/* <div className="col-lg-12 close showbook" data-showbook="false">x</div> */}
                                    <div className="col-lg-12 pb-2">
                                        <div className="custom-control custom-radio custom-control-inline fs-6">
                                            <input type="radio" id="bookingtypehotel" name="bookingtype" className="custom-control-input" value="" checked/>
                                            <label className="custom-control-label font-weight-bold text-white" for="bookingtypehotel">HOTEL</label>
                                        </div>
                                        
                                        <div className="custom-control custom-radio custom-control-inline fs-6">
                                            <input type="radio" id="bookingtypehotelair" name="bookingtype" className="custom-control-input" value="hotelflight"/>
                                            <label className="custom-control-label font-weight-bold text-white" for="bookingtypehotelair">HOTEL + AIR</label>
                                        </div>
                                    </div>
                                    
                                    <div className="col-lg-12 col-xs-12 pb-3 d-none airport">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-12 col-xs-12 m-auto">
                                                <div className="form-group text-start">
                                                    <label className="text-white pt-1 ms-4 fs-14">AIRPORT</label>
                                                    <input type="text" className="booking-form form-control rounded-0 outline-booking fs-14" id="airport" placeholder="Select airport"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-6 col-xs-6">
                                        <div className="form-group text-start">
                                        <label className="text-white pt-1 ms-4 fs-14">HOTEL</label>
                                            <select id="hotel" className="booking-form form-control rounded-0 outline-booking fs-14" required autoComplete="off">
                                                <option value="">Select Hotel</option>
                                                <option value="10868">Epic Beach</option>
                                                <option value="10869">Epic Residence</option>
                                                <option value="10867">Epic Dowtown</option>
                                                <option value="10866">Epic Oceanfront</option>
                                            </select>
                                        </div>
                                    </div>
                                    
                                    <div className="col-lg-3 col-md-6 col-xs-6">
                                        <div className="form-group text-start">
                                            <label className="text-white pt-1 ms-4 fs-14">CHECK IN - CHECK OUT</label>
                                            <input type="text" id="checkin" className="booking-form form-control rounded-0 outline-booking fs-14" placeholder="Selecciona dates" required autoComplete="off"/>
                                        </div>
                                    </div>

                                    <div className="col-lg-2 col-md-6 col-xs-12">
                                        <div className="form-group text-start">
                                            <label className="text-white pt-1 ms-4 fs-14">ADULT(S)</label>
                                            <select id="adults" className="booking-form form-control rounded-0 outline-booking fs-14" required autoComplete="off">
                                                <option value="01" selected>1</option>
                                                <option value="02">2</option>
                                                <option value="03">3</option>
                                                <option value="04">4</option>
                                                <option value="05">5</option>
                                                <option value="06">6</option>
                                                <option value="07">7</option>
                                                <option value="08">8</option>
                                                <option value="09">9</option>
                                                <option value="10">10</option>
                                            </select> 
                                        </div>
                                    </div>

                                    <div className="col-lg-2 col-md-6 col-xs-12">
                                        <div className="form-group text-start">
                                            <label className="text-white pt-1 ms-4 fs-14">CHILDREN</label>
                                            <select id="children" className="booking-form form-control rounded-0 outline-booking fs-14" autoComplete="off">
                                                <option value="0"  selected>0</option>
                                                <option value="01">1</option>
                                                <option value="02">2</option>
                                                <option value="03">3</option>
                                                <option value="04">4</option>
                                                <option value="05">5</option>
                                                <option value="06">6</option>
                                                <option value="07">7</option>
                                                <option value="08">8</option>
                                                <option value="09">9</option>
                                                <option value="10">10</option>
                                            </select> 
                                        </div>
                                    </div>

                                    <div className="col-lg-2 col-md-6 col-xs-12">
                                        <a className="w-100 btn btn-outline-light rounded-0 pt-2 pb-1 bookingengine text-white padding-form mt-4 fs-14">
                                            BOOK NOW
                                        </a>
                                    </div>

                                    <input type="hidden" name="lang" id="lang" value="2"/>

                                </div>


                            </form>
                        </div>
                    </div>
                </div>
        
            </div>
        
        </div>
    )
}

export default Bookingengine