import React from 'react'
import logo from '../../../assets/img/the-epic-logo-white.png'

export const Navbar = () => {

    return (
        <div>


            <nav className="navbar navbar-expand-lg fixed-top">
                <div className="container-fluid">
                    <a className="navbar-brand ms-4 me-2" href="#">
                        <img src={logo} className="d-inline-block align-text-top logo"   alt="The Epic Vacation Club"/>
                    </a>
                    
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item me-4">
                                <a className="nav-link scroll text-white fw-bold mr-4" href="#about">ABOUT US</a>
                            </li>

                            <li className="nav-item me-4">
                                <a className="nav-link scroll text-white fw-bold" href="#hotels">PROMOTIONS</a>
                            </li>

                            <li className="nav-item me-4">
                                <a className="nav-link scroll text-white fw-bold" href="#contact">CONTACT</a>
                            </li>

                            <li className="nav-item me-4">
                                <a className="nav-link  text-white fw-bold" href="/">ESP</a>
                            </li>
                            <li className="nav-item me-4 d-flex">
                                <a className="nav-link text-white fw-bold" href="tel:18008404070">USA/CAN: <span className="fw-light">1 800 840-4070</span></a>
                                <span className="fw-bold separator-tel"> | </span> 
                                <a className="nav-link text-white fw-bold" href="tel:+529842420313">REST OF THE WORLD: <span className="fw-light">+52 (984) 242-0313</span></a>
                            </li>
        
                        </ul>
                    </div>
                </div>
            </nav>

            <header className="masthead d-flex align-items-center">

                <div className="container-fluid text-center">

                    <h1 className="title text-center text-light text-shadow"  data-aos="fade-up" data-aos-duration="1500" >The Epic Vacation Club</h1>
                    <h3 className="price text-subtitle"  data-aos="fade-up" data-aos-duration="2000">Discover our packages</h3>
                    <a className="btn btn-book btn-lg mt-2 scroll" data-aos="fade-up" data-aos-duration="2200" href="#hotels">
                        BOOK NOW
                    </a>
                    <a className="scroll" href="#hotels">
                        <div className="arrow"></div>
                    </a>
                </div>

                {/* <div className="col-lg-12 text-center sticky-book">
                    <a className="btn btn-lg rounded-0 pt-2 pb-1 text-white  mt-4 background-epic showbook" data-showbook="false">
                        BOOK
                    </a>
                </div> */}


            </header>
            

        </div>
    )
}

export default Navbar