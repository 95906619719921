import React from 'react';
import { Helmet } from 'react-helmet';

const MetaHead = ({ title, description, image, url }) => (
  <Helmet>
    <title>{`${title}`}</title>
    <meta name="description" content={description} />
    <meta
      name="robots"
      content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
    />
    <link rel="canonical" href={url} />
    <meta property="og:locale" content="en_US" />
    <meta property="og:type" content="article" />
    <meta property="og:title" content={`The Fives  Vacations - ${title}`} />
    <meta property="og:description" content={description} />
    <meta property="og:url" content={url} />
    <meta property="og:site_name" content="The Fives  Vacations" />
    <meta property="og:image" content={image} />
    <meta property="og:image:secure_url" content={image} />
  </Helmet>
);

export default MetaHead;
