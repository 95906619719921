import React from 'react'
import { CarouselTFB } from '../../carousel/CarouselTFB'
import { CarouselTFD } from '../../carousel/CarouselTFD'
import { CarouselTFO } from '../../carousel/CarouselTFO'
import { CarouselTFR } from '../../carousel/CarouselTFR'

import tfduo from '../../../assets/img/the-epic-duo.jpg'

import { useEffect } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";

export const Hotels = () => {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);

    return (

        <section className="content-section" id="hotels">
            <div className="container px-5">
                <div className="row pb-lg-5">
                    <div className="col-lg-6 px-4" data-aos="fade-right" data-aos-duration="1500">
                        <CarouselTFB/>
                    </div>

                    <div className="col-lg-6 px-4" data-aos="fade-up" data-aos-duration="1500">
                        <h1 className="title text-center">Epic Vacation Club Beach</h1>
                        <p className="subtitle">Sumérgete en la serenidad de nuestro hotel AAA Four Diamond, ideal para unas vacaciones únicas y exclusivas. Relájate en nuestra playa de arena blanca y aguas turquesa, descubre nuestros restaurantes que te ofrecen platillos extraordinarios y sabores únicos. Visita el lugar perfecto para unas vacaciones familiares o escapadas románticas en el corazón del Caribe mexicano. </p>
                        <div className="row  padding-price">
                            <div className="col-lg-8">
                                <h2 className="price">Desde $39 USD*</h2>
                                <small className="subtitle taxes">*Tarifa para un mínimo de cuatro noches con check-in el domingo en plan todo incluido, excepto impuesto ambiental. </small>
                                <small className="subtitle taxes">*Tarifa por persona en ocupación doble. </small>                                
                                <small className="subtitle taxes">*Niños de hasta 12 años gratis.</small>                                
                                <small className="subtitle taxes">*Transportación aeropuerto-hotel incluida.</small>
                            </div>

                            <div className="col-lg-4 text-md-center">
                                <a className="btn btn-book btn-lg" target="_blank" rel="noreferrer" href="https://www.reservhotel.com/playa-del-carmen-mexico/epic-beach/booking-engine/ibe5.main?hotel=10868&showcalendar=Y">Reservar</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row pt-5 pb-lg-5">
                    <div className="col-lg-6 px-4" data-aos="fade-right" data-aos-duration="1500">
                        <CarouselTFD/>
                    </div>

                    <div className="col-lg-6 px-4" data-aos="fade-up" data-aos-duration="1500">
                        <h1 className="title text-center">Epic Vacation Club Downtown</h1>
                        <p className="subtitle">Comienza el día con nuevas experiencias en nuestro encantador hotel, dale un toque de glamour a tu estadía y experimenta la magia en el centro de Playa del Carmen. Disfruta de nuestra cocina gourmet internacional, unos tragos de tequila, nuestro café bar y déjate impresionar por la mejor vista del Caribe mexicano.</p>

                        <div className="row padding-price">
                            <div className="col-lg-8">
                                <h2 className="price">Desde $39 USD*</h2>
                                <small className="subtitle taxes">*Desayuno incluido.</small>
                                <small className="subtitle taxes">*Tarifa por habitación en ocupación doble.</small>
                                <small className="subtitle taxes">*No incluye impuestos.</small>                                
                                <small className="subtitle taxes">*Transportación aeropuerto-hotel incluida.</small>

                            </div>
                            
                            <div className="col-lg-4 text-md-center">
                                <a className="btn btn-book btn-lg" target="_blank" rel="noreferrer" href="https://www.reservhotel.com/playa-del-carmen-mexico/epic-downtown/booking-engine/ibe5.main?hotel=10867&showcalendar=Y">Reservar</a>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <div className="row pt-5 pb-lg-5">
                    <div className="col-lg-6 px-4" data-aos="fade-right" data-aos-duration="1500">
                        <CarouselTFO/>
                    </div>

                    <div className="col-lg-6 px-4" data-aos="fade-up" data-aos-duration="1500">
                        <h1 className="title text-center">Epic Vacation Club Oceanfront</h1>
                        <p className="subtitle">Descubre el secreto mejor guardado de la Riviera Maya: Puerto Morelos. Relájate o emprende una nueva aventura en nuestro hermoso hotel boutique en las costas de México. Disfruta del sol junto a una piscina infinita al aire libre, acompañado de un cóctel en el bar y los mejores platillos. Encuentra el ambiente revitalizante que necesitas.</p>

                        <div className="row  padding-price">
                            <div className="col-lg-8">
                                <h2 className="price">Desde $39 USD*</h2>
                                <small className="subtitle taxes">*Desayuno incluido.</small>
                                <small className="subtitle taxes">*Tarifa por habitación en ocupación doble.</small>
                                <small className="subtitle taxes">*No incluye impuestos.</small>                              
                                <small className="subtitle taxes">*Transportación aeropuerto-hotel incluida.</small>
                            </div>
                            
                            <div className="col-lg-4 text-md-center">
                                <a className="btn btn-book btn-lg" target="_blank" rel="noreferrer" href="https://www.reservhotel.com/cancun-mexico/epic-oceanfront/booking-engine/ibe5.main?hotel=10866&showcalendar=Y">Reservar</a>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="row pt-5 pb-lg-5">
                    <div className="col-lg-6 px-4" data-aos="fade-right" data-aos-duration="1500">
                        <CarouselTFR/>
                    </div>

                    <div className="col-lg-6 px-4" data-aos="fade-up" data-aos-duration="1500">
                        <h1 className="title text-center">Epic Residences</h1>
                        <p className="subtitle">Disfruta de un descanso excepcional en nuestro hotel AAA Four Diamond. Siente la comodidad de nuestras instalaciones de primer nivel con amplias habitaciones, siete maravillosas albercas y, sobre todo, relájate en nuestra playa de arena blanca e impresionantes aguas turquesa.</p>

                        <div className="row  padding-price">
                            <div className="col-lg-8">
                                <h2 className="price">Desde $39 USD*</h2>
                                <small className="subtitle taxes">*Desayuno incluido.</small>
                                <small className="subtitle taxes">*Tarifa por habitación en ocupación doble.</small>
                                <small className="subtitle taxes">*No incluye impuestos.</small>                                
                                <small className="subtitle taxes">*Transportación aeropuerto-hotel incluida.</small>
                            </div>
                            
                            <div className="col-lg-4 text-md-center">
                                <a className="btn btn-book btn-lg" target="_blank" rel="noreferrer" href="https://www.reservhotel.com/playa-del-carmen-mexico/epic-residences/booking-engine/ibe5.main?hotel=10869&showcalendar=Y">Reservar</a>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="row pt-5 pb-lg-5">
                    <div className="col-lg-6" data-aos="fade-right" data-aos-duration="1500">
                        <img src={tfduo} alt="Epic Residences" className="w-100"/>
                    </div>

                    <div className="col-lg-6 px-4" data-aos="fade-up" data-aos-duration="1500">
                        <h1 className="title text-center">Epic Duo</h1>
                        <p className="subtitle">¿Estás indeciso entre unas vacaciones llenas de música, energía y diversión o una escapada para el descanso y la relajación? Déjanos seducir tus emociones con nuestro paquete Epic Duo que te da la alternativa de vivir lo mejor de dos mundos: la emoción de Playa del Carmen y también el encanto y tranquilidad de Puerto Morelos. ¿En qué orden disfrutarlos? ¡Tú decides! Te ayudamos a diseñar las vacaciones de tus sueños.</p>

                        <div className="row  padding-price">
                            <div className="col-lg-8">
                                <h2 className="price">Desde $39 USD*</h2>
                                <small className="subtitle taxes">*Desayuno incluido.</small>
                                <small className="subtitle taxes">*Tarifa por habitación en ocupación doble.</small>
                                <small className="subtitle taxes">*No incluye impuestos.</small>                                
                                <small className="subtitle taxes">*Transportación aeropuerto-hotel incluida.</small>
                            </div>
                            
                            <div className="col-lg-4 text-md-center">
                                <a className="btn btn-book btn-lg" target="_blank" rel="noreferrer" href="https://landing.theepicvacationclub.com/sales/order?token=8c01b5663e38a278ecf2d63403dc02e596f4c54d&category=39&hotel=10">Reservar</a>
                            </div>
                        </div>

                    </div>
                </div>


                
            </div>
        </section>
    )

}

  
export default Hotels